@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@600&display=swap');

$brandColor:#755BB4;
$white: #fff;
$black: #000;
$brandColorShadow:#755bb46b;
$brandColorDarkShadow: #0501106b;
$brandShadow:0 1px 3px 0 $brandColorShadow,
0 1px 2px 0 $brandColorShadow;

body {
   font-family: 'Libre Franklin', sans-serif;
   background-color: $brandColor;
}

ol {
   list-style: none;
}

ul {
   list-style: none;
}

a {
   text-decoration: none;
   color: inherit;
}


img,
video {
   display: block;
   max-width: 100%;
   height: auto;
   object-fit: cover;
}

img {
   width: 150px;
}


.container {
   max-width: 75rem;
   height: auto;
   margin: 0 auto;
   padding: 0 1.25rem;
}

// Headers
.header {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 100;
   width: 100%;
   height: auto;
   margin: 0 auto;
   background-color: $white;
   box-shadow: $brandColorShadow;
}

.navbar {
   display: flex;
   flex-direction: row;
   align-items: center;
   align-content: center;
   justify-content: space-between;
   width: 100%;
   height: 4.25rem;
   margin: 0 auto;
}

.brand {
   font-family: inherit;
   font-size: 1.6rem;
   font-weight: 600;
   line-height: 1.25;
   margin-right: auto;
   letter-spacing: -1px;
   text-transform: uppercase;
   color: $brandColor;

   img {
      width: 100px;
      height: 50px;
   }
}

.menu {
   position: fixed;
   top: 0;
   left: -100%;
   z-index: 10;
   width: 100%;
   height: 100vh;
   overflow: hidden;
   color: $brandColor;
   background-color: $white;
   transition: all 0.4s ease-in-out;

   &.is-active {
      left: 0;
   }

   &-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 1.25rem;
      margin-top: 7rem;
   }

   &-link {
      font-family: inherit;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5;
      color: inherit;
      text-transform: uppercase;
      transition: all 0.3s ease;
   }

   // Responsive Media Query
   @media only screen and (min-width: 48rem) {
      position: relative;
      top: 0;
      left: 0;
      width: auto;
      height: auto;
      padding: 0rem;
      margin-left: auto;
      background: none;
      box-shadow: none;
      transition: none;

      &-inner {
         display: flex;
         flex-direction: row;
         column-gap: 2rem;
         margin: 0 auto;
      }

      &-link {
         text-transform: capitalize;
      }
   }
}

.burger {
   position: relative;
   display: block;
   cursor: pointer;
   order: -1;
   z-index: 12;
   width: 1.75rem;
   height: 1rem;
   margin-right: 1rem;
   border: none;
   outline: none;
   background: none;
   visibility: visible;
   transform: rotate(0deg);
   transition: 0.35s ease;

   @media only screen and (min-width: 48rem) {
      display: none;
      visibility: hidden;
   }

   &-line {
      position: absolute;
      display: block;
      right: 0;
      width: 100%;
      height: 2px;
      border: none;
      outline: none;
      opacity: 1;
      transform: rotate(0deg);
      background-color: $brandColor;
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
         top: 0px;
      }

      &:nth-child(2) {
         top: 0.5rem;
      }

      &:nth-child(3) {
         top: 1rem;
      }
   }

   &.is-active .burger-line {
      &:nth-child(1) {
         top: 0.5rem;
         transform: rotate(135deg);
      }

      &:nth-child(2) {
         right: -1.5rem;
         opacity: 0;
         visibility: hidden;
      }

      &:nth-child(3) {
         top: 0.5rem;
         transform: rotate(-135deg);
      }
   }
}

.main-wrapper {
   background-image: linear-gradient(120deg, $brandColor 0%, $brandColorShadow 80%);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin: 4rem;
   padding: 5rem 0 2rem;
}

.posts {
   line-height: 1;

   .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: $white;
      background-color: $white;
      height: 200px;
      padding: 1rem;
      margin-bottom: 10px;
      border-radius: 10px;
      box-shadow: 0 4px 12px 0 $brandColorShadow;
      overflow: hidden;

      &-one {
         // background-image: url("https://picsum.photos/id/89/500/300");
         background-size: cover;
         background-position-x: center;
         background-position-y: bottom;
      }

      &-two {
         // background-image: url("https://picsum.photos/id/715/500/300");
         background-size: cover;
         background-position: center;
      }

      .top {
         display: flex;
         justify-content: space-between;
         opacity: 0.8;

         .actions {
            i {
               margin-left: 0.5em;
               transition: transform 200ms ease-in-out;

               &:hover {
                  cursor: pointer;
                  transform: scale(1.2) translatey(-0.2em);
               }
            }
         }
      }

      .bottom {
         position: relative;
         z-index: 1;
         transform: translateY(40%);
         transition: transform 500ms ease;

         &::after {
            z-index: -1;
            content: "";
            position: absolute;
            bottom: -1em;
            left: -1em;
            right: 0;
            width: calc(100% + 2rem);
            height: calc(100% + 5rem);
            background: linear-gradient(to top, rgba(0 0 0 / 0.9), rgba(0 0 0 / 0));
         }

         &:hover {
            transform: translateY(0);

            &::after {
               transform: translateY(0);
            }

            h1 {
               &::after {
                  transform: scaleX(1);
                  transform-origin: left;
               }
            }
         }

         h1 {
            display: inline-block;
            font-size: 2.5em;
            position: relative;
            margin-bottom: 0.3em;

            &::after {
               position: absolute;
               content: "";
               bottom: -0.2em;
               left: -2rem;
               width: calc(100% + 2rem);
               height: 0.1em;
               background-color: $brandColorShadow;
               transform: scaleX(0);
               transition: transform 600ms ease;
               transform-origin: right;
            }
         }

         .author {
            display: flex;
            align-items: center;
            margin-bottom: 2px;
            opacity: 0.8;
         }

         .author-img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 4px;
         }

         .text {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            p {
               font-weight: 100;
            }

            button {
               font-size: 0.8em;
               padding: 0.5em 0.8em;
               margin-top: 3px;
               margin-left: 10px;
               border-radius: 5px;
               color: $white;
               background-color: $brandColor;

               &:hover {
                  cursor: pointer;
                  color: $brandColor;
                  background-color: $white;
               }
            }
         }
      }

   }

}

.post {
   &.container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      padding: 2rem;
      box-shadow: 0 0 50px $brandColorDarkShadow;
   }

   &-header {
      color: $white;
      font-size: 3rem;
   }

   &-content {
      color: $white;
      margin-bottom: 2ch;

      ol,
      ul ::before,
      ol,
      ul ::after {
         list-style: unset;
         list-style-type: unset;
         text-decoration: unset;
         -webkit-font-smoothing: antialiased;
         -moz-osx-font-smoothing: grayscale;
         text-rendering: optimizeLegibility;
      }

      p {
         font-size: 1rem;
         margin: 0;
      }

      h1 {
         font-size: 1.5rem;
      }

      pre {
         background-color: #f5f5f5;
         padding: 15px;
         border-radius: 5px;
         overflow-x: auto;
         font-size: 14px;
         color: #333;
      }

   }

   &-meta {
      display: flex;
      flex-direction: row;
      place-items: center;
      color: $white;

      div {
         margin: 0 10px;
      }

      .author-img {
         width: 50px;
         height: 50px;
         border-radius: 50%;
         margin-right: 4px;
      }
   }

}

@media only screen and (max-width: 700px) {
   .posts {
      .card {
         .bottom {
            transform: translateY(30%);

            h1 {
               font-size: 2rem;
            }

            .author-img {
               width: 30px;
               height: 30px;
               border-radius: 50%;
               margin-right: 4px;
            }
         }

      }

   }
}


// coming soon

// .coming {
//    @keyframes fadeIn {
//       from {
//          top: 20%;
//          opacity: 0;
//       }
//       to {
//          top: 100;
//          opacity: 1;
//       }
//    }
//    @-webkit-keyframes fadeIn {
//       from {
//          top: 20%;
//          opacity: 0;
//       }
//       to {
//          top: 100;
//          opacity: 1;
//       }
//    }
//    &.wrapper {
//       position: absolute;
//       left: 50%;
//       top: 50%;
//       transform: translate(-50%, -50%);
//       -webkit-transform: translate(-50%, -50%);
//       animation: fadeIn 1000ms ease;
//       -webkit-animation: fadeIn 1000ms ease;
//    }
//    h1 {
//       font-size: 50px;
//       font-family: 'Poppins', sans-serif;
//       margin-bottom: 0;
//       line-height: 1;
//       font-weight: 700;
//       color: $white;
//    }
//    .dot {
//       color: $brandColor;
//    }
//    p {
//       text-align: center;
//       img {
//          height: 200px;
//       }
//    }
//    .icons {
//       text-align: center;
//       i {
//          color: $brandColor;
//          background: $white;
//          height: 15px;
//          width: 15px;
//          padding: 13px;
//          margin: 0 10px;
//          border-radius: 50px;
//          border: 2px solid $white;
//          transition: all 200ms ease;
//          text-decoration: none;
//          position: relative;
//          &:hover {
//             color: $white;
//             background: none;
//             cursor: pointer !important;
//             transform: scale(1.2);
//             -webkit-transform: scale(1.2);
//             text-decoration: none;
//          }
//          &:active {
//             color: $white;
//             background: none;
//             cursor: pointer !important;
//             transform: scale(1.2);
//             -webkit-transform: scale(1.2);
//             text-decoration: none;
//          }
//       }
//    }

// }

.center {
   text-align: center;
}

.pagination {
   display: inline-block;
   a {
       color: $black;
       float: left;
       padding: 8px 16px;
       text-decoration: none;
       transition: background-color .3s;
       border: 1px solid $brandColorShadow;
       margin: 0 4px;
       &.active {
           background-color: $brandColor;
           color: $white;
           border: 1px solid $brandColor;
       }
   }
}

// footer
.footer-distributed {
   background-color: #000;
   box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
   box-sizing: border-box;
   width: 100%;
   text-align: left;
   font: bold 16px sans-serif;
   padding: 55px 50px;
 }
 
 .footer-distributed .footer-left,
 .footer-distributed .footer-center,
 .footer-distributed .footer-right {
   display: inline-block;
   vertical-align: top;
 }
 
 /* Footer left */
 
 .footer-distributed .footer-left {
   width: 40%;
 }
 
 /* The company logo */
 
 .footer-distributed h3 {
   color: #ffffff;
   font: normal 36px "Cookie", cursive;
   margin: 0;
 }
 
 .footer-distributed h3 span {
   color: #5383d3;
 }
 
 /* Footer links */
 
 .footer-distributed .footer-links {
   color: #ffffff;
   margin: 20px 0 12px;
   padding: 0;
 }
 
 .footer-distributed .footer-links a {
   display: inline-block;
   line-height: 1.8;
   text-decoration: none;
   color: inherit;
 }
 
 .footer-distributed .footer-company-name {
   color: #8f9296;
   font-size: 14px;
   font-weight: normal;
   margin: 0;
 }
 
 /* Footer Center */
 
 .footer-distributed .footer-center {
   width: 35%;
 }
 
 .footer-distributed .footer-center i {
   background-color: #33383b;
   color: #ffffff;
   font-size: 25px;
   width: 38px;
   height: 38px;
   border-radius: 50%;
   text-align: center;
   line-height: 42px;
   margin: 10px 15px;
   vertical-align: middle;
 }
 
 .footer-distributed .footer-center i.fa-envelope {
   font-size: 17px;
   line-height: 38px;
 }
 
 .footer-distributed .footer-center p {
   display: inline-block;
   color: #ffffff;
   vertical-align: middle;
   margin: 0;
 }
 
 .footer-distributed .footer-center p span {
   display: block;
   font-weight: normal;
   font-size: 14px;
   line-height: 2;
 }
 
 .footer-distributed .footer-center p a {
   color: #5383d3;
   text-decoration: none;
 }
 
 /* Footer Right */
 
 .footer-distributed .footer-right {
   width: 20%;
 }
 
 .footer-distributed .footer-company-about {
   line-height: 20px;
   color: #92999f;
   font-size: 13px;
   font-weight: normal;
   margin: 0;
 }
 
 .footer-distributed .footer-company-about span {
   display: block;
   color: #ffffff;
   font-size: 14px;
   font-weight: bold;
   margin-bottom: 20px;
 }
 
 .footer-distributed .footer-icons {
   margin-top: 25px;
 }
 
 .footer-distributed .footer-icons a {
   display: inline-block;
   width: 35px;
   height: 35px;
   cursor: pointer;
   background-color: #33383b;
   border-radius: 2px;
 
   font-size: 20px;
   color: #ffffff;
   text-align: center;
   line-height: 35px;
 
   margin-right: 3px;
   margin-bottom: 5px;
 }
 
 /* If you don't want the footer to be responsive, remove these media queries */
 
 @media (max-width: 880px) {
   .footer-distributed {
     font: bold 14px sans-serif;
   }
 
   .footer-distributed .footer-left,
   .footer-distributed .footer-center,
   .footer-distributed .footer-right {
     display: block;
     width: 100%;
     margin-bottom: 40px;
     text-align: center;
   }
 
   .footer-distributed .footer-center i {
     margin-left: 0;
   }
 }
 